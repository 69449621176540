import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack mb-6" }
const _hoisted_2 = { class: "fw-bolder my-2" }
const _hoisted_3 = { class: "fs-6 text-gray-400 fw-bold ms-1" }
const _hoisted_4 = { class: "d-flex my-2" }
const _hoisted_5 = { class: "row g-6 g-xl-9" }
const _hoisted_6 = { class: "row g-6 g-xl-9" }
const _hoisted_7 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardCompany = _resolveComponent("CardCompany")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_CompaniesCU = _resolveComponent("CompaniesCU")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("companies")) + " ", 1),
        _createElementVNode("span", _hoisted_3, "(" + _toDisplayString(_ctx.companies.length) + ")", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.create_company)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.newCompany && _ctx.newCompany(...args)))
            }, _toDisplayString(_ctx.$t("btnNew")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companiesList, (cg) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cg.id,
          class: "col-xl-4"
        }, [
          _createVNode(_component_CardCompany, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            color: "primary",
            item: cg,
            onUpdate: _ctx.editCompany,
            onDelete: _ctx.deleteCompany
          }, null, 8, ["item", "onUpdate", "onDelete"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.companiesList.length > 0)
          ? (_openBlock(), _createBlock(_component_el_pagination, {
              key: 0,
              currentPage: _ctx.page,
              "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
              "page-sizes": [
          Math.round(_ctx.companies.length / 6),
          Math.round(_ctx.companies.length / 3),
          Math.round(_ctx.companies.length / 2),
          Math.round(_ctx.companies.length / 1),
        ],
              "page-size": _ctx.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.companies.length,
              "page-count": Math.round(_ctx.companies.length / _ctx.pageSize),
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.setPage
            }, null, 8, ["currentPage", "page-sizes", "page-size", "total", "page-count", "onSizeChange", "onCurrentChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.typeEvent !== '')
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.dialogVisible,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
          width: "50%",
          onClose: _ctx.resetModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CompaniesCU, {
              "type-event": _ctx.typeEvent,
              item: _ctx.element,
              onSuccess: _ctx.successOperation
            }, null, 8, ["type-event", "item", "onSuccess"])
          ]),
          _: 1
        }, 8, ["modelValue", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}