import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "d-flex flex-stack" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "symbol symbol-60px me-5" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3" }
const _hoisted_7 = {
  href: "javascript:void(0)",
  class: "text-dark fw-bolder text-hover-primary fs-5"
}
const _hoisted_8 = { class: "text-muted fw-bold" }
const _hoisted_9 = {
  key: 0,
  class: "ms-1 dropdown"
}
const _hoisted_10 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary dropdown-toggle",
  id: "dropdownMenuButton1",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = {
  class: "dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px",
  "aria-labelledby": "dropdownMenuButton1"
}
const _hoisted_13 = {
  key: 0,
  class: "menu-item px-3"
}
const _hoisted_14 = {
  key: 1,
  class: "menu-item px-3"
}
const _hoisted_15 = { class: "card-p pt-5 bg-white flex-grow-1" }
const _hoisted_16 = { class: "row g-0 mt-2" }
const _hoisted_17 = { class: "col mr-8" }
const _hoisted_18 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_19 = { class: "fs-4 fw-bolder" }
const _hoisted_20 = { class: "row g-0 mt-4" }
const _hoisted_21 = { class: "col mr-4" }
const _hoisted_22 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_23 = { class: "d-flex align-items-center" }
const _hoisted_24 = { class: "fs-4 fw-bolder" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_27 = { class: "fs-4 fw-bolder" }
const _hoisted_28 = { class: "row g-0 mt-4" }
const _hoisted_29 = { class: "col mr-4" }
const _hoisted_30 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_31 = { class: "fs-4 fw-bolder" }
const _hoisted_32 = { class: "col" }
const _hoisted_33 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_34 = { class: "d-flex align-items-center" }
const _hoisted_35 = { class: "fs-4 fw-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass([`bg-${_ctx.color}-light`, "symbol-label"])
            }, [
              (_ctx.item.logo)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.item.logo,
                    class: "h-50 align-self-center",
                    alt: ""
                  }, null, 8, _hoisted_5))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass([`bg-light-primary text-info`, "symbol-label fs-5 fw-bolder"])
                  }, _toDisplayString(_ctx.item.name.match(/\b(\w)/g).join("")), 1))
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.item.name), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.item.created_at), 1)
          ])
        ]),
        (_ctx.update_companies || _ctx.delete_companies)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotone/Layout/Layout-4-blocks-2.svg" })
                ])
              ]),
              _createElementVNode("ul", _hoisted_12, [
                (_ctx.update_companies)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                      _createElementVNode("div", {
                        class: "dropdown-item menu-content fs-6 text-info fw-bolder px-3 py-4",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', _ctx.item)))
                      }, _toDisplayString(_ctx.$t("btnEdit")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.delete_companies)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                      _createElementVNode("div", {
                        class: "dropdown-item menu-content fs-6 text-danger fw-bolder px-3 py-4",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete', _ctx.item.id)))
                      }, _toDisplayString(_ctx.$t("btnDelete")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("iaddress")), 1),
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.item.address), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("icif")), 1),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.item.CIF), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("iemail")), 1),
            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.item.email), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("icompaniesgroup")), 1),
            _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.item.company_group_id), 1)
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("ientityreference")), 1),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.item.entity_id), 1)
            ])
          ])
        ])
      ])
    ])
  ], 2))
}