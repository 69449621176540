
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { computed, defineComponent, onMounted, ref } from "vue";
import CardCompany from "@/components/cards/CardCompany.vue";
import CompaniesCU from "./CompaniesCU.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { hasPermission } from "@/core/helpers/functions";
export default defineComponent({
  name: "ManagementCompanies",
  components: {
    CardCompany,
    CompaniesCU,
  },
  setup() {
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/ban-types
    const companies = ref<Array<object>>([]);
    const typeEvent = ref("");
    const dialogVisible = ref(false);
    const element = ref({});
    const page = ref(1);
    const pageSize = ref(3);

    const getCompanies = () => {
      ApiService.get("/api/companies").then(({ data }) => {
        companies.value = data;
      });
    };

    const newCompany = () => {
      typeEvent.value = "create";
      dialogVisible.value = true;
    };

    const editCompany = (item) => {
      element.value = item;
      typeEvent.value = "update";
      dialogVisible.value = true;
    };

    const deleteCompany = (id) => {
      Swal.fire({
        text: "Esta seguro que desea eliminar esta empresa",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/api/companies/" + id).then(() => {
            const i = companies.value.findIndex(
              (e: Record<string, any>) => e.id === id
            );
            companies.value.splice(i, 1);
          });
        }
      });
    };

    const successOperation = (response) => {
      const index = companies.value.findIndex(
        (el: Record<string, any>) => el.id === response.id
      );
      if (index >= 0) {
        companies.value[index] = response;
      } else {
        companies.value.push(response);
      }
      dialogVisible.value = false;
    };

    const resetModal = () => {
      typeEvent.value = "";
      element.value = {};
    };

    const handleSizeChange = (val) => {
      page.value = 1;
      pageSize.value = val;
    };

    const setPage = (val) => {
      page.value = val;
    };

    const companiesList = computed(() => {
      if (store.getters.searchLocal === "") {
        return (
          companies.value.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      } else {
        const result = companies.value.filter((ele: Record<string, any>) =>
          ele.name
            .toLowerCase()
            .includes(store.getters.searchLocal.toLowerCase())
        );
        return (
          result.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(
        "companiesIndex",
        ["companies", "companies"],
        true
      );
      getCompanies();
    });
    return {
      newCompany,
      editCompany,
      deleteCompany,
      successOperation,
      resetModal,
      handleSizeChange,
      setPage,
      companies,
      companiesList,
      typeEvent,
      dialogVisible,
      element,
      page,
      pageSize,
      create_company: ref(hasPermission("companies: create")),
    };
  },
});
